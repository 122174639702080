import "@cloudscape-design/global-styles/index.css";
import type {AppProps} from 'next/app'

/**
 * Workaround for CloudScape Design useLayoutEffect
 * details: https://www.codewithyou.com/blog/cloudscape-design-with-nextjs
 */
import React from 'react'

if (typeof window === 'undefined') React.useLayoutEffect = () => {
}

/**
 * Main Application Component
 * @param Component
 * @param pageProps
 * @constructor
 */
export default function App({Component, pageProps}: AppProps) {
  return <Component {...pageProps} />
}
